import axios from "axios";

const state = {
  user: {},
  logueado: false,
  loading: false,
  status: JSON.parse(localStorage.getItem('status') || "false" ),
  token: JSON.parse(localStorage.getItem('token') || "{}" ),
  btn_login: false,

  //errors login
  error_login: false,
};

const getters = {
  get_user: state => state.user,
  get_logueado: state => state.logueado,
  get_loading: state => state.loading,
  get_authenticated: state => state.status,
  get_token: state => state.token,
  get_btn_login: state => state.btn_login,

  //wrong user or password
  get_error_login: state => state.error_login,

};

const actions = {
  login ({ commit }, user) {
    commit('set_btn_login', true);
      axios.post(process.env.VUE_APP_API_URL+ '/api/login', user)
        .then(response => {
          localStorage.setItem('status',JSON.stringify(response.data.status))
          localStorage.setItem('token',JSON.stringify(response.data.access_token))
          commit('set_user', response.data)
          commit('set_error_login', false)
          commit('set_logueado', true)
        })
        .catch(error => {
          console.log(error)
          commit('set_btn_login', false)
          commit('set_error_login', true)
        })
 },


 logout ({ commit }) {
  commit('set_btn_login', true);
  axios.get(process.env.VUE_APP_API_URL+ '/api/logout')
      .then( commit('clearUserData'))
},
};

const mutations = {
  clearUserData: () => {
    localStorage.removeItem('token')
    localStorage.removeItem('status')
  },

  set_user: (state, user) => state.user = user,
  set_logueado: (state, logueado) => state.logueado = logueado,
  set_authenticated: (state, status) => state.status = status,
  set_btn_login:(state, btn_login) => state.btn_login = btn_login,

  set_error_login:(state, error_login) => state.error_login = error_login,
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
}