<template>
  <div class="background-color-header">
    <navbar-mobile/>
    <navbar-desktop/>
    <v-main>
      <router-view/>
    </v-main>
    <footer-desktop/>
  </div>
</template>

<script>
import NavbarMobile from '@/components/Navbar/NavbarMobile'
import NavbarDesktop from '@/components/Navbar/NavbarDesktop'
import FooterDesktop from '@/components/FooterDesktop'

export default {
  name: 'Layout-',
  components: {
      NavbarDesktop,
      NavbarMobile,
      FooterDesktop,

  },
}
</script>

<style>
@font-face {
  font-family: Poppins-Medium;
  src: url('@~/public/fonts/Poppins-Medium.ttf');
}
@font-face {
  font-family: Poppins-SemiBold;
  src: url('@~/public/fonts/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: Poppins-Regular;
  src: url('@~/public/fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: Aller-Bold;
  src: url('@~/public/fonts/Aller_Std_Bd.ttf');
}
@font-face {
  font-family: Aller-Regular;
  src: url('@~/public/fonts/Aller_Std_Rg.ttf');
}

.Poppins-Medium{
  font-family: "Poppins-Medium";
}
.Poppins-SemiBold{
  font-family: "Poppins-SemiBold";
}
.Poppins-Regular{
  font-family: "Poppins-Regular";
}
.Aller{
  font-family: "Aller";
}

.background-color-header {
  background-color: #E9EDEE;
  width: 100%;
  height: 100%;
}
/* .background-color-header {
  background: rgb(219,237,243);
  background: linear-gradient(90deg, rgba(219,237,243,1) 0%, rgba(223,239,243,1) 61%, rgba(197,214,226,1) 100%);
} */
</style>
