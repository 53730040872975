<template>
  <div>
    <v-bottom-navigation app color="#CB9865" background-color="#E9EDEE">
      <v-btn height="100%" href="/" color="#E9EDEE">
        <span>INICIO</span>
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>

      <v-btn height="100%" href="areas-de-practica" color="#E9EDEE">
        <span>ÁREAS DE PRÁCTICA</span>
        <v-icon>mdi-scale-balance</v-icon>
      </v-btn>
      <v-btn height="100%" @click="idNews()" color="#E9EDEE">
          <span>BLOG</span>
          <v-icon>mdi-post-outline</v-icon>
      </v-btn>

      <v-btn height="100%" href="#contacto" color="#E9EDEE">
        <span>CONTACTO</span>
        <v-icon>mdi-account-box-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  computed: mapGetters(['get_todas_noticias_bis']),

  methods: {
    ...mapActions(['selectedNew']),
  
    idNews () {
      if (this.get_todas_noticias_bis == '') {
        this.$router.push("/blog");
      } else {
        let idNewSelected = this.get_todas_noticias_bis.length
        this.selectedNew(idNewSelected)
      }
    },
  },
};
</script>