import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

Vue.config.productionTip = false

Vue.use({
  install (Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: true,
    })
  }
})

axios.defaults.headers.common.Authorization = `Bearer ${store.getters.get_token}`

new Vue({
  vuetify,
  store,
  router,
  created(){
    AOS.init();
  },
  render: h => h(App)
}).$mount('#app')
