<template>
  <div v-if="$vuetify.breakpoint.smAndDown">
    <login-mobile />
  </div>
  <div v-else>
    <login-desktop />
  </div>
</template>

<script>
import LoginMobile from "../components/Login/LoginMobile";
import LoginDesktop from "../components/Login/LoginDesktop";
export default {
  name: 'Login-General',
  components: { LoginMobile, LoginDesktop},
}
</script>