<template>
  <div class="background-layout-registrado">
    <overlay-load :overlay="get_btn_login"/>
    <navbar-registrado-mobile/>
    <navbar-registrado-desktop/>
    <v-main>
      <router-view/>
    </v-main>
  </div>
</template>

<script>
import OverlayLoad from "../components/OverlayLoad";
import NavbarRegistradoMobile from '@/components/Navbar/NavbarRegistradoMobile'
import NavbarRegistradoDesktop from '@/components/Navbar/NavbarRegistradoDesktop'
import {mapGetters} from "vuex";

export default {
  name: 'Layout-Registrado',
  components: {
    OverlayLoad,
    NavbarRegistradoMobile,
    NavbarRegistradoDesktop,
  },

  computed: mapGetters(['get_btn_login', 'get_noticia']),
}
</script>

<style>
.background-layout-registrado {
  background-color: #E9EDEE;
  width: 100%;
  height: 100%;
}
</style>