<template>
    <div class="Poppins-SemiBold" v-if="$vuetify.breakpoint.smOnly || $vuetify.breakpoint.xsOnly" >
        <bottom-navigation-mobile-registrado/>
    </div>
</template>

<script>
import BottomNavigationMobileRegistrado from '../BottomNavigationMobileRegistrado.vue'
export default {
    components: { BottomNavigationMobileRegistrado,},
}
</script>