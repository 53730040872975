<template>
  <div>
    <v-bottom-navigation app color="#CB9865" background-color="#E9EDEE">
      <v-img
        max-width="60"
        max-height="60"
        class="mr-4"
        src='/img/logo-header.png'
      />
      
      <v-btn height="100%" to="/listado-noticias" color="#E9EDEE">
        <span>NOTICIAS</span>
        <v-icon>mdi-post-outline</v-icon>
      </v-btn>

      <v-btn height="100%" to="/subir-noticia" color="#E9EDEE">
        <span>AGREGAR NOTICIA</span>
        <v-icon>mdi-text-box-plus-outline</v-icon>
      </v-btn>

      <v-btn height="100%" color="#E9EDEE" @click="onLogout()">
        <span>SALIR</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  computed: mapGetters(['get_user']),

  methods: {
    ...mapActions({
      salir: 'logout',
    }),

    onLogout() {
      this.salir();
      this.$router.go(0);
    },
  },
};
</script>