<template>
  <div class="py-8 px-4 mx-sm-16">
    <v-row class="pt-3">
      <v-col cols="12" sm="12" md="12">
        <title-section textTitle="AGREGAR NOTICIA"/>
      </v-col>
    </v-row>

    <form @submit.prevent="storeNews()" enctype="multipart/form-data">
      <v-card
        class="grey lighten-3 pa-6 my-4"
        flat
        elevation="0"
        align="start"
      >
        <div class="Poppins-Regular pb-4">(*) Campo Obligatorio</div>
        <v-row no-gutters class="pb-4" justify="start">
          <v-col cols="12" sm="12" lg="12" class="pr-lg-2">
            <label-input texto="TÍTULO *"/>
            <v-text-field
              v-model="news.titulo"
              placeholder="Título de la noticia"
              class="Poppins-Regular"
              prepend-inner-icon="mdi-pencil-outline"
              outlined
              required
              :rules="tituloRules"
              counter="255"
              color="#CB9865"
            />
          </v-col>
          <!--<v-col cols="12" sm="12" lg="6" class="pr-lg-2">
            <label-input texto="FECHA"/>
            <v-text-field
              placeholder="27/05/2022"
              class="Poppins-Regular"
              prepend-inner-icon="mdi-calendar-month-outline"
              outlined
              readonly
              color="#CB9865"
            />
          </v-col>-->
        </v-row>

        <v-row no-gutters justify="start">
          <v-col cols="12" sm="12" lg="12" class="pr-lg-2">
            <label-input texto="COPETE"/>
            <v-textarea
                v-model="news.copete"
                height="100px"
                class="Poppins-Regular"
                filled
                placeholder="Breve descripción de la noticia..."
                color="#CB9865"
                counter="500"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="12" lg="12" class="pr-lg-2">
            <label-input texto="CUERPO *"/>
            <v-textarea
              v-model="news.cuerpo"
              class="Poppins-Regular"
              filled
              required
              counter="3000"
              :rules="cuerpoRules"
              color="#CB9865"
            />
          </v-col>
        </v-row>

        <v-row no-gutters justify="start">
          <v-col cols="12" class="pr-lg-2">
            <label-input texto="IMAGEN"/>
            <input class="Poppins-Regular" type="file" @change="FileUpload($event)" />
          </v-col>
        </v-row>
      </v-card>

      <div v-show="get_error_save_news"> 
        <v-alert
          text
          dense
          type="error"
          border="left"
          class="Poppins-Regular my-6 py-6"
        >
          Por favor, compuebe que la cantidad de caracteres escritos en cada campo sea el correcto
        </v-alert>
      </div>
      <v-row no-gutters justify="center">
        <v-btn
          class="pa-5 color Poppins-SemiBold"
          height="4"
          elevation="0"
          color="#CB9865"
          outlined
          rounded
          type="submit"
          :disabled="this.$store.getters.get_loading_btn_store"
        >
          <v-icon class="px-2"> mdi-check-bold </v-icon>
          PUBLICAR
        </v-btn>

        <v-dialog
          v-model="get_show_modal_exito_noticia"
          max-width="500px"
          persistent
        >
          <v-card class="pa-6">
            <div class="Poppins-Medium sizeText pb-4">
              ¡La noticia fue creada con éxito!
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="close"
                elevation="0"
                color="#CB9865"
                class="px-5 Poppins-Medium"
                outlined
                rounded
              >
                <v-icon left> mdi-close-thick </v-icon>
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </form>
    <v-overlay :value="this.$store.getters.get_loading_btn_store">
      <v-progress-circular indeterminate size="60" />
    </v-overlay>

  </div>
</template>

<script>
import LabelInput from '@/components/LabelInput';
import TitleSection from '@/components/TitleSection';
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'Add-news',

  components: { LabelInput, TitleSection, },

  data: () => ({
    news: {
      titulo: '',
      copete: '',
      cuerpo: '',
      imagen: '',
    },
    tituloRules: [
      v => !!v || "Escriba un título",
      v => (v && v.length >= 5) || 'El título debe tener más de 5 caracteres',
    ],
    cuerpoRules: [
      v => !!v || "Escriba el texto de la noticia",
      v => (v && v.length >= 20) || 'El texto debe tener más de 20 caracteres',
    ],
  }),

  methods: {
    FileUpload(event){
      let file = event.target.files[0]; //porque es una sola imagen
      this.news.imagen = file;
    },

    storeNews() {
      let formData = new FormData();
      formData.append("titulo", this.news.titulo);
      formData.append("copete", this.news.copete);
      formData.append("cuerpo", this.news.cuerpo);
      formData.append("imagen", this.news.imagen);

      this.storeBlog(formData);
    },

    close() {
      this.$emit("close");
      this.$router.go(0);
    },

    ...mapActions([ 'storeBlog', ]),
  },

  computed: {
    ...mapGetters([
      'get_user',
      'get_authenticated',
      'get_btn_login',
      'get_show_modal_exito_noticia',
      'get_error_save_news',
    ]),
  },
}
</script>
<style>
.sizeText{
  font-family: "Poppins-Medium";
  font-size: 1.3rem;
  color: #151316;
}
</style>