<template>
  <div>
    <v-dialog persistent v-model="show" max-width="580px">
      <v-card class="pa-4">
        <v-card-title class="Poppins-Medium">¿Está segura que desea eliminar esta noticia?</v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="pa-5 color Poppins-SemiBold"
            height="4"
            elevation="0"
            color="#CB9865"
            outlined
            rounded
            @click="deleteItemConfirm"
          >Sí, eliminar</v-btn>
          <v-btn
            class="pa-5 color Poppins-SemiBold"
            height="4"
            elevation="0"
            color="grey"
            outlined
            rounded
            @click="show=false"
          >Cancelar</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";
export default {
  name: 'Dialog-delete-new',
  props: {
    value: Boolean,
    data: Object,
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    ...mapActions(['destroyBlog']),

    deleteItemConfirm () {
      let idDelete = this.data;
      this.destroyBlog(idDelete);
      this.show = false;
    },
  },
}
</script>