<template>
    <v-card
      class="transparent"
      flat
      elevation="0"
      align="start"
    >
        <v-row align="center">
          <v-col cols="12" md="6" sm="12">
              <v-img :src="img1" class="responsive-imgen ma-8"/>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="pa-6">
              <div class="Poppins-SemiBold titleSection text-justify"> {{text}} </div>
              <div class="line"/>
              <div class="Poppins-Regular sizeDescriptionAboutMe pt-8">
                <v-avatar class="mr-2" size="40" tile>
                  <v-img :src="img2"/>
                </v-avatar>
                {{descriptionOne}}
              </div>
              <div class="Poppins-Regular sizeDescriptionAboutMe">
                <v-avatar class="mr-2" size="40" tile>
                  <v-img :src="img3"/>
                </v-avatar>
                {{descriptionTwo}}
              </div>
              <div class="Poppins-Regular sizeDescriptionAboutMe">
                <v-avatar class="mr-2" size="40" tile>
                  <v-img :src="img4"/>
                </v-avatar>
                {{descriptionThree}}
              </div>
              <v-btn
                rounded
                color="#cb9865"
                outlined
                class="Poppins-SemiBold mt-16 mb-4"
                large
                @click="show = !show"
              >
                  Ver presentación personal
                  <v-icon class="pl-2">
                    {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
              </v-btn>
          </v-col>
          <v-card v-if="show" flat class="px-8" color="#F3F7F8">
            <v-card-text class="descriptionDerecho">
              Especialista en el área de asesoría jurídica Empresarial, Comercial y de los
              Negocios, formada en la Universidad de Buenos Aires, en la prestigiosa Maestría de
              Derecho Comercial y de los Negocios, además he ampliado mi preparación, con el
              Master en Asesoría Jurídica Empresarial, perteneciente a La Universidad de Nebrija
              (España) a través de Euroinnova Business School, como así también he realizado
              Posgrado de propiedad intelectual y me encuentro en constante preparación sobre
              las actualizaciones que surgen en el derecho, tanto nacional como internacional,
              capaz de aportar conocimientos para la planificación y gestión en función de un buen
              gobierno corporativo.
            </v-card-text>
            <v-card-text class="descriptionDerecho">
              Competente en la elaboración de contratos, para la formación, fusión de empresas
              y compraventa de acciones. Además, tengo idoneidad en el Patentamiento de Marcas
              e Innovaciones. Me destaco en la elaboración del marco normativo y la correcta
              aplicación del compliance jurídico. Asimismo, poseo la aptitud para el asesoramiento
              y la representación en litigios, en todas las áreas, como ser; Derecho de daños,
              Contrato de seguros, Derecho de Familia (Alimentos, Sucesiones, etc.) Procesos
              Laborales, Previsionales etc.
            </v-card-text>
          </v-card>
        </v-row>
    </v-card>
</template>
<script>
export default {
  name: 'CardAboutMe',

  props: {
    img1: String,
    img2: String,
    img3: String,
    img4: String,
    text: String,
    descriptionOne: String,
    descriptionTwo: String,
    descriptionThree: String,
  },

  data(){
    return {
      show: false,
    }
  }
}
</script>

<style>
@font-face {
  font-family: Poppins-SemiBold;
  src: url('@~/public/fonts/Poppins-SemiBold.ttf');
}
.Poppins-SemiBold{
  font-family: "Poppins-SemiBold";
}

.sizeDescriptionAboutMe{
  font-size: 20px;
  text-align: justify;
  padding-top: 20px;
}

.responsive-imgen{
  height: auto;
  max-width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
}
</style>