<template>
  <div class="full">
    <v-container fill-height fluid>
      <v-row justify="center" align="center">
        <v-card
          class="centrar py-5 px-5"
          style="border-radius: 20px"
          align="center"
          color="#E9EDEE"
        >
          <v-row justify="center" align="center">
            <v-col cols="12" md="6">
              <v-img max-width="500px" src='/img/logo.svg' />
            </v-col>
            <v-col cols="12" md="6">
              <v-card-text>
                <form @submit.prevent="onLogin" enctype="multipart/form-data">
                  <div class="login-text text-justify pt-8 pb-2">
                    <v-icon color="#CB9865">mdi-account</v-icon>
                    USUARIO:
                  </div>
                  <v-text-field
                    :color=" this.$store.getters.get_errores ? 'red lighten-1' : '#CB9865' "
                    :background-color=" this.$store.getters.get_errores ? 'red lighten-4' : 'white' "
                    outlined
                    v-model="credentials.name"
                    name="user.name"
                  ></v-text-field>

                  <div class="login-text text-justify pb-2">
                    <v-icon color="#CB9865">mdi-lock</v-icon>
                    CONTRASEÑA:
                  </div>
                  <v-text-field
                    :color=" this.$store.getters.get_errores ? 'red lighten-1' : '#CB9865' "
                    :background-color=" this.$store.getters.get_errores ? 'red lighten-4' : 'white' "
                    outlined
                    v-model="credentials.password"
                    name="user.password"
                  ></v-text-field>

                  <div v-show="get_error_login"> 
                    <v-alert
                      text
                      dense
                      type="error"
                      border="left"
                      class="Poppins-Regular my-6 py-6"
                    >
                      Usuario o contraseña incorrectos
                    </v-alert>
                  </div>

                  <v-btn
                    :disabled="this.$store.getters.get_btn_login"
                    type="submit"
                    class="mt-3 pa-5 login-text mr-2"
                    x-large
                    elevation="0"
                    color="#CB9865"
                  >
                    Ingresar
                  </v-btn>
                </form>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
    <v-overlay :value="this.$store.getters.get_btn_login">
      <v-progress-circular indeterminate size="60"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginDesktop",

  data() {
    return {
      credentials: {
        name: null,
        password: null,
      },
    };
  },

  computed: {
    ...mapGetters([
      "get_error_login",
      "get_logueado",
    ]),
  },

  watch: {
    get_logueado(value) {
      if (value) this.$router.go(0);
    },
  },

  methods: {
    ...mapActions({
      login: "login",
      verificar: "verificar",
    }),

    onLogin() {
      (this.loading = true), this.login(this.credentials);
    },
  },
};
</script>

<style>
.full {
  background: url('@~/public/img/background-login.svg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.login-text {
  font-family: "Poppins-Medium";
  font-size: 1.0rem;
  color: #CB9865;
}
</style>