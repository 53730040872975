<template>
  <div class="py-8 px-4 mx-sm-16">
      <title-section textTitle="EDITAR"/>
      <form @submit.prevent="saveChanges()" enctype="multipart/form-data">
        <v-row class="pa-4" justify="start">
          <div class="Poppins-Regular pb-4">(*) Campo Obligatorio</div>
            <v-col
              cols="12"
              md="12"
            >
              <label-input texto="TÍTULO *"/>
              <v-text-field
                v-model="blog.titulo"
                color="#CB9865"
                class="Poppins-Regular"
                counter="255"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <label-input texto="COPETE"/>
              <v-textarea
                v-model="blog.copete"
                height="100px"
                class="Poppins-Regular"
                filled
                color="#CB9865"
                counter="500"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <label-input texto="CUERPO *"/>
              <v-textarea
                v-model="blog.cuerpo"
                class="Poppins-Regular"
                color="#CB9865"
                filled
                counter="3000"
              />
            </v-col>
        </v-row>
      
        <div v-show="get_error_edit_news"> 
          <v-alert
            text
            dense
            type="error"
            border="left"
            class="Poppins-Regular my-6 py-6"
          >
            Por favor, compuebe que la cantidad de caracteres escritos en cada campo sea el correcto
          </v-alert>
        </div>
        <v-row no-gutters justify="space-around" class="mt-10">
          <v-btn
            class="pa-5 color Poppins-SemiBold"
            height="4"
            elevation="0"
            color="#CB9865"
            outlined
            rounded
            type="submit"
            :disabled="this.$store.getters.get_loading_btn_edit"
          >
            <v-icon class="px-2"> mdi-content-save </v-icon> 
            Guardar
          </v-btn>
          <v-btn
            class="pa-5 color Poppins-SemiBold"
            height="4"
            elevation="0"
            color="grey"
            outlined
            rounded
            href="/listado-noticias"
            :disabled="this.$store.getters.get_loading_btn_edit"
          >
            <v-icon class="px-2"> mdi-close </v-icon> 
            Cancelar
          </v-btn>

          <v-dialog
            v-model="get_show_modal_edit_noticia"
            max-width="500px"
            persistent
          >
            <v-card class="pa-6">
              <div class="Poppins-Medium sizeText pb-4">
                ¡La noticia fue editada con éxito!
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="closeModalSuccessEdit"
                  elevation="0"
                  color="#CB9865"
                  class="px-5 Poppins-Medium"
                  outlined
                  rounded
                >
                  <v-icon left> mdi-close-thick </v-icon>
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </form>
    <v-overlay :value="this.$store.getters.get_loading_btn_edit">
      <v-progress-circular indeterminate size="60" />
    </v-overlay>
  </div>
</template>

<script>
import TitleSection from '@/components/TitleSection';
import LabelInput from '@/components/LabelInput';
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'Edit-new',
  components: {TitleSection, LabelInput},

  data() {
    return {
      blog: {
        titulo: this.$store.getters.get_noticia.titulo,
        copete: this.$store.getters.get_noticia.copete,
        cuerpo: this.$store.getters.get_noticia.cuerpo,
      }
    }
  },

  methods: {
    saveChanges () {
      let formData = new FormData();
      formData.append("id", this.get_noticia.id);
      formData.append("titulo", this.blog.titulo);
      formData.append("copete", this.blog.copete);
      formData.append("cuerpo", this.blog.cuerpo);
      
      this.updateBlog(formData)
    },
    
    closeModalSuccessEdit() {
      this.showModalSuccessEdit(false)
      this.$emit("close");
      this.$router.push('/listado-noticias');
    },
    
    ...mapActions(['updateBlog', 'showModalSuccessEdit']),
  },
  
  computed: {
    ...mapGetters([
      'get_noticia',
      'get_show_modal_edit_noticia',
      'get_error_edit_news',
    ]),
  },
  
}
</script>