<template>
  <div class="my-16">
    <v-row justify="center">
      <div class="Poppins-SemiBold titleSection py-6"> 
        ÁREAS DE PRÁCTICA 
      </div>
    </v-row>
    <v-row no-gutters justify="space-around" align="center">
      <v-col cols="12" md="4" sm="6" xs="12" class="py-6 px-2">
          <card-area-practica img="./img/empresarial.svg" text="DERECHO DE EMPRESAS, COMERCIAL Y CORPORATIVO" />
      </v-col>
      <v-col cols="12" md="4" sm="6" xs="12" class="py-6 px-2">
          <card-area-practica img="./img/previsional.svg" text="DERECHO PREVISIONAL (jubilaciones y pensiones)" />
      </v-col>
      <v-col cols="12" md="4" sm="6" xs="12" class="py-6 px-2">
          <card-area-practica img="./img/consumidor.svg" text="DERECHO DEL CONSUMIDOR" />
      </v-col>
      <v-col cols="12" md="3" sm="6" xs="12" class="py-6 px-2">
          <card-area-practica img="./img/bancario.svg" text="DERECHO BANCARIO" />
      </v-col>
      <v-col cols="12" md="3" sm="6" xs="12" class="py-6 px-2">
          <card-area-practica img="./img/intelectual2.svg" text="DERECHO INTELECTUAL" />
      </v-col>
      <v-col cols="12" md="3" sm="6" xs="12" class="py-6 px-2">
          <card-area-practica img="./img/civil.svg" text="DERECHO CIVIL" />
      </v-col>
      <v-col cols="12" md="3" sm="6" xs="12" class="py-6 px-2">
          <card-area-practica img="./img/laboral.svg" text="DERECHO LABORAL" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-btn
        rounded
        color="#cb9865"
        outlined
        class="Poppins-SemiBold mt-8 mb-4 px-4"
        to="areas-de-practica"
        large
      >
        Leer más información
        <v-icon class="pl-2"> mdi-chevron-right </v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
  import CardAreaPractica from '@/components/Cards/CardAreaPractica.vue'

  export default {
    name: 'Area-Practica-Section',
    
    components: {
      CardAreaPractica,
    },
  }
</script>
