<template>
  <div class="py-8 px-4 mx-sm-16">
    <v-row class="py-3" justify="center">
      <v-col cols="12" sm="12" md="12">
        <title-section textTitle="NOTICIAS"/>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="this.$store.getters.get_todas_noticias"
      :loading="this.$store.getters.get_loading_table"
      :items-per-page="5"
      class="mytable"
      mobile-breakpoint="300"
      loading-text="Cargando noticias. Por favor, espere."
      no-data-text="No hay noticias cargadas"
    >
      <template v-slot:[`item.actionEdit`]="{ item }">
        <v-btn @click="editItem(item)" fab small color="grey lighten-1" depressed>
          <v-icon size="20px"> mdi-pencil </v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.actionDelete`]="{ item }">
        <v-btn @click="deleteItem(item)" fab small color="grey lighten-2" depressed>
          <v-icon size="20px"> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <!--<v-overlay :value="this.$store.getters.get_loading_table">
      <v-progress-circular indeterminate size="60" />
    </v-overlay>-->

    <dialog-delete-new
      :data="SelectedData"
      v-model="dialogDelete"
    />
  </div>
</template>

<script>
import TitleSection from '@/components/TitleSection';
import DialogDeleteNew from '@/components/DialogDeleteNew.vue';
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'All-news',
  components: {TitleSection, DialogDeleteNew},

  data: () => ({
    SelectedData: {},
    dialogDelete: false,
    headers: [
      {
        text: 'TÍTULO',
        align: 'start',
        sortable: true,
        value: 'titulo',
        width: "75%",
      },
      {
          text: 'EDITAR',
          align: 'center',
          value: 'actionEdit',
          sortable: false
      },
      {
          text: 'ELIMINAR',
          align: 'center',
          value: 'actionDelete',
          sortable: false
      },
    ],
  }),

  computed: mapGetters (['get_todas_noticias','get_loading_table']),

  mounted() {
    this.indexBlog();
  },

  methods: {
    ...mapActions(['indexBlog', 'showBlog']),

    editItem (item) {
      let idBlog = item.id
      this.showBlog(idBlog)
    },

    deleteItem (item) {
      this.SelectedData = item.id
      this.dialogDelete = true
    },
  },
}
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th > span {
  font-size: 18px !important;
}

.mytable thead {
  background-color: #BDBDBD !important;
  font-family: "Poppins-Regular" !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-family: "Poppins-Regular" !important;
  font-size: 16px !important;
  padding: 15px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover {
  background-color: #EEEEEE !important;
}
</style>