import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/Layout'
import LayoutRegistrado from '../layout/LayoutRegistrado'
import Home from '../views/Home.vue'
import AreasDePractica from '../views/AreasDePractica.vue'
import Blog from '../views/Blog.vue'
import AddNews from '../views/Registered/AddNews.vue'
import AllNews from '../views/Registered/AllNews.vue'
import EditNew from '../views/Registered/EditNew.vue'
import Login from '../views/Login.vue'

import auth from "../middleware/auth";
import guest from "../middleware/guest";
import middlewarePipeline from "./middlewarePipeline";
import store from "../store/index";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: Layout,
    children: 
    [
      {
        path: '',
        name: 'Home',
        component: Home,
        meta: { title: 'Estudio Jurídico - Analía Avalos', middleware: [guest] }
      },
      {
        path: 'areas-de-practica',
        name: 'Areas-de-Practica',
        component: AreasDePractica,
        meta: { title: 'Estudio Jurídico - Áreas de Práctica', middleware: [guest] }
      },
      {
        path: 'blog',
        name: 'Mi-blog',
        component: Blog,
        meta: { title: 'Estudio Jurídico - Blog', middleware: [guest] }
      },
    ]
  },
  {
    path: '/login',
    name: 'Login-General',
    component: Login,
    meta: {title: 'Ingresar', middleware: [guest] }
  },
  {
    path: '/listado-noticias',
    name: '',
    component: LayoutRegistrado,
    children: 
    [
      {
        path: '/listado-noticias',
        name: 'All-news',
        component: AllNews,
        meta: {title: 'Listado de noticias', middleware: [auth] }
      },
      {
        path: '/editar-noticia',
        name: 'Edit-new',
        component: EditNew,
        meta: {title: 'Editar noticia', middleware: [auth] }
      },
      {
        path: '/subir-noticia',
        name: 'Add-news',
        component: AddNews,
        meta: {title: 'Subir noticia', middleware: [auth] }
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };

  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router