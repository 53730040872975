<template>
  <div>
    <v-card
      class="transparent px-8"
      flat
      elevation="0"
    >
      <v-row align="center" v-if="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly">
        <v-col cols="12" lg="8" md="7" align="center">
          <v-img
            max-width="400px"
            src='/img/logo-header.png'
          />
          <div class="logoName">ESTUDIO JURIDICO ANALIA AVALOS</div>
          <div class="perfil">ABOGADA ESPECIALISTA EN ASESORÍA JURÍDICA EMPRESARIAL</div>
          <v-btn
            rounded
            color="#cb9865"
            outlined
            class="Poppins-SemiBold mt-8 mb-6 px-4"
            href="#contacto"
          >
              Contactame
              <v-icon class="pl-2">
                mdi-chevron-right
              </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" lg="4" md="5">
          <v-img
            src='/img/analia-header.png'
          />
        </v-col>
      </v-row>
      <v-row v-else align="center">
        <v-col cols="12" align="center">
          <v-img
            max-width="300px"
            src='/img/logo-header.png'
          />
          <div class="logoName">ESTUDIO JURIDICO ANALIA AVALOS</div>
          <div class="perfil">ABOGADA ESPECIALISTA EN ASESORÍA JURÍDICA EMPRESARIAL</div>
          <v-btn
            rounded
            color="#cb9865"
            outlined
            class="Poppins-SemiBold mt-8 mb-6"
            href="#contacto"
            large
          >
              Contactame
              <v-icon class="pl-2">
                mdi-chevron-right
              </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>

  export default {
    name: 'HeaderSection',
    
    components: {
    },
  }
</script>

<style>
.logoName {
  font-family: "Aller-Bold";
  font-size: 2.0rem;
  color: #CB9865;
}
.perfil {
  font-family: "Aller-Regular";
  font-size: 1.3rem;
  color: #151316;
}

/*
@media screen and (min-width: 960px) {
  div.perfil {
    font-size: 21px;
  }
}
@media screen and (max-width: 959px) {
  div.perfil {
    font-size: 22px;
  }
}*/
</style>