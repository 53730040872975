<template>
  <div class="Poppins-SemiBold" v-if="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly" >
    <v-app-bar class="px-16 mb-3" height="70px" color="transparent" elevation="1">
      <v-tabs v-model="userProfileTabs" :show-arrows="false" color="#CB9865" background-color="transparent">
        <v-img
          max-width="70px"
          class="mr-2"
          src='/img/logo-header.png'
        />
        <v-tab to="/listado-noticias">NOTICIAS</v-tab>
        <v-tab to="/subir-noticia">AGREGAR NOTICIA</v-tab>
        <v-spacer></v-spacer>
        <v-btn height="70" color="#757575" text @click="onLogout()">SALIR</v-btn>
      </v-tabs>
      <v-tabs-items v-model="userProfileTabs" id="custom-tab-items">
        <v-tab-item value="tabs-1"/>
        <v-tab-item value="tabs-2"/>
      </v-tabs-items>
    </v-app-bar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  data: () => ({
    userProfileTabs: "",
  }),

  computed: mapGetters(['get_user']),

  methods: {
    ...mapActions({
      salir: 'logout',
    }),

    onLogout() {
      this.salir();
      this.$router.go(0);
    },
  },
};
</script>