<template>
  <div :class="{'px-6': $vuetify.breakpoint.smAndDown, 'px-16': $vuetify.breakpoint.mdAndUp, 'my-8': $vuetify.breakpoint.mdAndUp}">
    <div class="Poppins-SemiBold titleSection my-6 text-uppercase"> {{get_noticia.titulo}} </div>
    <v-row no-gutters justify="space-between" align="start">
      <v-col cols="12" md="7" sm="12" class="py-6">
        <div>
          <img
            :src="link"
            class="responsive-img mb-6"
          />
          <div v-html="get_noticia.copete" class="copeteNews mb-6"></div>
          <div v-html="get_noticia.cuerpo" class="cuerpoNews mb-6"></div>
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="py-6">
        <v-card
          max-width="full"
        >
          <v-toolbar
            color="#cb9865"
            dark
          >
            <v-toolbar-title>Noticias</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-icon>mdi-scale-balance</v-icon>
          </v-toolbar>

          <v-list color="#F3F7F8">
            <v-list-item-group
              class="Poppins-Regular"
              active-class="Poppins-Medium"
            >
              <template v-for="(item, i) in (get_todas_noticias_bis)">
                <v-list-item :key="i" @click="changeNew(item)">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.titulo"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action class="Poppins-Regular">
                      <!--<v-list-item-action-text v-text="item.created_at" />-->

                      <v-icon
                        v-if="!active"
                        color="grey lighten-1"
                      >
                        mdi-newspaper-variant-multiple
                      </v-icon>

                      <v-icon
                        v-else
                        color="#cb9865"
                      >
                        mdi-newspaper-variant-multiple
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>

</template>

<script>
  import {mapGetters, mapActions} from "vuex";

  export default {
    name: 'Mi-blog',
    data() { 
      return {       
        link: 'https://apirest.avalosestudio.com.ar/storage/imagenes/' + this.$store.getters.get_noticia.imagen
      }
    },

    computed: mapGetters(['get_noticia', 'get_todas_noticias_bis']),

    mounted() {
      this.indexBisBlog();
    },

    methods: {
      ...mapActions (['selectedNew', 'indexBisBlog']),

//      changeNew(item) {
//        this.get_noticia.id = item.id
//        this.get_noticia.titulo = item.titulo
//        this.get_noticia.copete = item.copete
//        this.get_noticia.cuerpo = item.cuerpo
//        this.link = item.imagen
//      },
        changeNew(item) {
          let idNewSelected = item.id
          this.link = 'https://apirest.avalosestudio.com.ar/storage/imagenes/' + item.imagen
          this.selectedNew(idNewSelected)
        },
    },
    
  }
</script>
<style>
  .responsive-img{
    max-width: 500px;
    height: auto;
  }
</style>