<template>
  <div :class="{'px-6': $vuetify.breakpoint.smAndDown, 'px-16': $vuetify.breakpoint.mdAndUp}">
    <header-section/>
    <about-me-section/>
    <area-practica-section/>
    <blog-section/>
  </div>

</template>

<script>
import HeaderSection from '@/components/HomeSections/HeaderSection'
import AboutMeSection from '@/components/HomeSections/AboutMeSection'
import AreaPracticaSection from '@/components/HomeSections/AreaPracticaSection'
import BlogSection from '@/components/HomeSections/BlogSection'
import {mapGetters} from "vuex";

  export default {
    name: 'MyHome',
    
    components: {
      HeaderSection,
      AboutMeSection,
      AreaPracticaSection,
      BlogSection,
    },

    computed: {
      ...mapGetters([
        'get_todas_noticias_bis',
      ]),
    },
  }
</script>
