<template>
  <div class="Poppins-SemiBold" v-if="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly" >
    <v-app-bar class="px-16 mb-3" height="60" color="transparent" elevation="0">
      <v-tabs v-model="userProfileTabs" :show-arrows="false" color="#CB9865" background-color="transparent">
        <v-tab to="/">INICIO</v-tab>
        <v-tab to="areas-de-practica">ÁREAS DE PRÁCTICA</v-tab>
        <v-tab
          @click="idNews()"
        >
          BLOG
        </v-tab>
        <v-btn
          text
          href="#contacto"
          color="#757575"
          height="60"
        >
          CONTACTO
        </v-btn>
      </v-tabs>
      <v-tabs-items v-model="userProfileTabs" id="custom-tab-items">
          <v-tab-item value="tabs-1"/>
          <v-tab-item value="tabs-2"/>
          <v-tab-item value="tabs-3"/>
      </v-tabs-items>
    </v-app-bar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  data: () => ({
    userProfileTabs: "",
  }),

  computed: mapGetters(['get_todas_noticias_bis']),

  methods: {
    ...mapActions(['selectedNew']),
  
    idNews () {
      if (this.get_todas_noticias_bis == '') {
        this.$router.push("/blog");
      } else {
        let idNewSelected = this.get_todas_noticias_bis.length
        this.selectedNew(idNewSelected)
      }
    },
  },
};
</script>