import axios from "axios";
import router from "../../router";

const state = {
  //Array
  noticia: [],
  todas_noticias: [],
  // No registrado
  todas_noticias_bis: [],
  last_news: [],
  delete_news: [],

  //Boolean
  show_modal_exito_noticia: false, //Modal: la noticia fue creada con éxito
  show_modal_edit_noticia: false, //Modal: la noticia fue editada con éxito
  loading_table: true, //Carga de la tabla
  loading_btn_store: false, //Carga del botón "PUBLICAR" de blog-store
  loading_btn_edit: false, //Carga del botón "GUARDAR" de blog-update
  show: false,
  
  //errores
  error_news: [],
  error_save_news: false,
  error_edit: [],
  error_edit_news: false,

  //fecha: '',
};

const getters = {
  get_noticia: state => state.noticia,
  get_delete_news: state => state.delete_news,
  get_last_news: state => state.last_news,
  get_show_modal_exito_noticia: state => state.show_modal_exito_noticia,
  get_show_modal_edit_noticia: state => state.show_modal_edit_noticia,
  get_todas_noticias: state => state.todas_noticias,
  get_todas_noticias_bis: state => state.todas_noticias_bis,
  get_loading_table: state => state.loading_table,
  get_loading_btn_store: state => state.loading_btn_store,
  get_loading_btn_edit: state => state.loading_btn_edit,

  //get errores
  get_error_news: state => state.error_news,
  get_error_save_news: state => state.error_save_news,
  get_error_edit: state => state.error_edit,
  get_error_edit_news: state => state.error_edit_news,
};

const actions = {
  // GUARDA LA NOTICIA
  storeBlog ({ commit }, noticia) {
    commit('set_loading_btn_store', true)
    axios.post(process.env.VUE_APP_API_URL+ '/api/blog-store', noticia)
      .then(response => {
        commit('set_noticia', response.data)
        commit('set_loading_btn_store', false)
        commit('set_error_save_news', false)
        commit('set_show_modal_exito_noticia', true)
      })
      .catch(error => {
        commit('set_error_save_news', error.response.data)
        commit('set_loading_btn_store', false)
        commit('set_error_save_news', true)
      })
  },
  
  // MUESTRA LAS NOTICIAS
  indexBlog ({ commit }) {
    axios.get(process.env.VUE_APP_API_URL+ '/api/blog')
      .then(response => {
        commit('set_todas_noticias', response.data)
        commit('set_loading_table', false)
      })
  },

  // MUESTRA UNA NOTICIA (identificando con el id que envío)
  showBlog ({ commit }, idblog) {
    let id = (idblog)
    axios.get(process.env.VUE_APP_API_URL+ '/api/blog-show/' + id)
    .then(response => {
      commit('set_noticia', response.data)
      router.push('/editar-noticia');
    })
  },

  // ACTUALIZA LA NOTICIA
  updateBlog ({commit}, blog) {
    commit('set_loading_btn_edit', true)
    axios.post(process.env.VUE_APP_API_URL+ '/api/blog-update', blog)
      .then(response => {
        commit('set_noticia', response.data)
        commit('set_loading_btn_edit', false)
        commit('set_error_edit_news', false)
        commit('set_show_modal_edit_noticia', true)
      })
      .catch(error => {
        commit('set_error_edit', error.response.data)
        commit('set_loading_btn_edit', false)
        commit('set_error_edit_news', true)
      })
  },

  // ELIMINA UNA NOTICIA
  destroyBlog ({ commit }, idDelete) {
    let id = (idDelete)
    axios.post(process.env.VUE_APP_API_URL+ '/api/blog-delete/' + id)
      .then(response => {
        commit('set_delete_news', response.data)
        router.go(0);
      })
  },

  //////////////////////////////////////////////////////////////////////
  // NO REGISTRADO
  //////////////////////////////////////////////////////////////////////

  // MUESTRA LAS NOTICIAS EN HOME
  indexBisBlog ({ commit }) {
    axios.get(process.env.VUE_APP_API_URL+ '/api/blog-bis')
      .then(response => {
        commit('set_todas_noticias_bis', response.data)
      })
  },

  //MUESTRA EN '/blog' LA NOTICIA QUE SELECCIONÉ EN HOME '/'
  selectedNew ({commit}, idNewSelected) {
    let id = (idNewSelected)
    axios.get(process.env.VUE_APP_API_URL+ '/api/blog-edit/' + id)
        .then(response => {
          commit('set_noticia', response.data)
          router.push('/blog');
        })
  },

  //MUESTRA POR DEFECTO EN '/blog' LA ÚLTIMA NOTICIA QUE SE SUBIÓ
  showLastNews ({commit}) {
    axios.get(process.env.VUE_APP_API_URL+ '/api/blog-ultnoticia')
        .then(response => {
          commit('set_last_news', response.data)
        })
  },

  //PROBAR SI FUNCIONA
  showModalSuccessEdit ({commit}, modal) {
    commit('set_show_modal_edit_noticia', modal)
  },
};

const mutations = {
  set_noticia: (state, noticia) => state.noticia = noticia,
  set_delete_news: (state, delete_news) => state.delete_news = delete_news,
  set_last_news: (state, last_news) => state.last_news = last_news,
  set_show_modal_exito_noticia: (state, show_modal_exito_noticia) => state.show_modal_exito_noticia = show_modal_exito_noticia,
  set_show_modal_edit_noticia: (state, show_modal_edit_noticia) => state.show_modal_edit_noticia = show_modal_edit_noticia,
  set_todas_noticias: (state, todas_noticias) => state.todas_noticias = todas_noticias,
  set_todas_noticias_bis: (state, todas_noticias_bis) => state.todas_noticias_bis = todas_noticias_bis,
  set_loading_table: (state, loading_table) => state.loading_table = loading_table,
  set_loading_btn_store: (state, loading_btn_store) => state.loading_btn_store = loading_btn_store,
  set_loading_btn_edit: (state, loading_btn_edit) => state.loading_btn_edit = loading_btn_edit,

  // set errores
  set_error_news: (state, error_news) => state.error_news = error_news,
  set_error_save_news: (state, error_save_news) => state.error_save_news = error_save_news,
  set_error_edit: (state, error_edit) => state.error_edit = error_edit,
  set_error_edit_news: (state, error_edit_news) => state.error_edit_news = error_edit_news,
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}